<script>
import ApiService from "@services/api.service";
import { FormWizard, TabContent } from "vue-form-wizard";
import Cleave from "vue-cleave-component";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "@core/scss/vue/libs/vue-wizard.scss";
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  name: "SalesAccountDetail",
  components: {
    FormWizard,
    TabContent,
    Cleave
  },
  props: {
    salesCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      salesAccount: {
        code: null, //
        username: null, // gausah muncul
        isActive: false,
        fullname: null, //
        dob: null, //
        joinDate: null, //
        resignDate: null, //
        emailAddress: null,
        phone: null,
        address: null,
        positionCode: null, //
        branchCode: null
      },

      parameter: {
        positionList: [],
        phoneOption: {
          phone: true,
          phoneRegionCode: "ID"
        }
      }
    };
  },
  computed: {
    editMode() {
      return this.salesCode != null;
    }
  },
  created() {
    if (this.salesCode) {
      this.loadSalesAccount();
    }
    this.loadPosition();
  },

  methods: {
    loadSalesAccount() {
      this.changeLoader(true);
      let qs = {
        code: this.salesCode
      };
      ApiService.get("SalesAccount/detail", qs)
        .then(resp => {
          this.salesAccount = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    loadPosition() {
      this.changeLoader(true);
      ApiService.get("position")
        .then(resp => {
          this.parameter.positionList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    checkPersonal() {
      if (
        !this.salesAccount.username ||
        !this.salesAccount.fullname ||
        !this.salesAccount.emailAddress ||
        !this.salesAccount.dob ||
        !this.salesAccount.address ||
        !this.salesAccount.phone
      ) {
        this.showToast("info", "Please finish all forms before moving to the next one");
        return false;
      } else {
        return true;
      }
    },

    checkPosition() {
      if (!this.salesAccount.nik || !this.salesAccount.positionCode || !this.salesAccount.joinDate || !this.salesAccount.resignDate) {
        this.showToast("info", "Please finish all forms before moving to the next one");
        return false;
      } else {
        return true;
      }
    },

    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("salesAccount", this.salesAccount)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.$router.push({ name: "management-position" });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("salesAccount", this.salesAccount)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.$router.push({ name: "management-position" });
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    clearForm() {
      this.product = {
        code: null,
        productCategoryCode: null,
        description: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-center">
          <h2>{{ !editMode ? `Create User Account` : `Edit User Account` }}</h2>
        </b-col>
      </b-row>
      <hr />
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="vertical-steps steps-transparent mb-3"
        @on-complete="save"
      >
        <!-- Position information tab -->
        <tab-content title="Personal Information" icon="feather icon-user" :before-change="checkPersonal">
          <b-row>
            <b-col md="4" align-self="center">
              <label>Fullname</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="salesAccount.fullname" class="form-control" />
              <small>User Account Full Name</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Username</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="salesAccount.username" class="form-control" />
              <small>Username for User Account</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>DOB</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-form-datepicker v-model="salesAccount.dob" class="form-control" />
              <small>User date of birth</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Email</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="salesAccount.emailAddress" type="email" class="form-control" />
              <small>User Email Address</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Phone Number</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-form-group label="Phone Number" label-for="phone">
                <b-input-group>
                  <b-input-group-prepend is-text> ID (+62) </b-input-group-prepend>
                  <cleave
                    id="phone"
                    v-model="salesAccount.phone"
                    class="form-control"
                    :raw="false"
                    :options="parameter.phoneOption"
                    placeholder="1234 567 8900"
                  />
                </b-input-group>
              </b-form-group>
              <small>User Email Address</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Address</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-textarea v-model="salesAccount.address" rows="5" class="form-control" />
              <small>User Address</small>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Position" icon="feather icon-book" :before-change="checkPosition">
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>NIK</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="salesAccount.nik" type="number" class="form-control" />
              <small>User NIK</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Position</label>
            </b-col>
            <b-col md="8" align-self="center">
              <v-select v-model="salesAccount.positionCode" label="description" :options="parameter.positionList" :reduce="x => x.code" />
              <small>User Position for user account</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Join Date</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-form-datepicker today-button reset-button="true" close-button="true" v-model="salesAccount.joinDate" class="form-control" />
              <small>User Joining Date</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Resign Date</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-form-datepicker today-button reset-button="true" close-button="true" v-model="salesAccount.resignDate" class="form-control" />
              <small>User Resigning Date</small>
            </b-col>
          </b-row>
        </tab-content>
        <!-- 
        <tab-content title="Sales" icon="feather icon-user" :before-change="checkSales">
          <b-row>
            <b-col md="4" align-self="center">
              <label>Sales NIK</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="position.salesAccountCode" type="number" class="form-control" />
              <small>Sales assigned to this Position</small>
            </b-col>
          </b-row>
        </tab-content>  -->
      </form-wizard>
    </b-card>
  </div>
</template>
